import { DefaultTheme } from "styled-components"

const myTheme: DefaultTheme = {
  gradient: {
    light_purple: "linear-gradient(180deg, #A277BC 0%, #BBA2D4 100%);",
    dark_purple: "linear-gradient(180deg, #34263D 0%, #745E8A 100%);",
    steel_purple: "linear-gradient(180deg, #ffffff 0%, #6054A8 100%);",
  },
  color: {},
  fontSize: {},
  fontWeight: {
    thin: "400",
    normal: "500",
    bold: "600",
  },
  lineHeight: {
    average: "26px",
  },
}

export { myTheme }
