import React from "react"
import { ThemeProvider } from "styled-components"
import { myTheme } from "./src/my-theme.ts"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
)

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const registerServiceWorker = () => true
